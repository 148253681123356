<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-row>
      <b-col cols="8">
        <b-card-code
          class="height-full"
          :title="$t('tttk')"
        >
          <validation-observer ref="simpleRules">
            <b-form class="form_custom">
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="họ tên"
                      rules="required"
                    >
                      <label
                        for="name"
                        :class="errors.length > 0 ? 'text-danger' : ''"
                      >{{ $t("name") }} <span class="text-danger">(*)</span></label>
                      <b-form-input
                        id="name"
                        v-model="frm.name"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <label
                        for="email"
                        :class="errors.length > 0 ? 'text-danger' : ''"
                      >{{ $t("email") }} <span class="text-danger">(*)</span></label>
                      <b-form-input
                        id="email"
                        v-model="frm.email"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('email')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Số điện thoại"
                    >
                      <label
                        for="phone-number"
                      >{{ $t('sdt') }}</label>
                      <b-form-input
                        id="phone-number"
                        v-model="frm.phoneNumber"
                        type="number"
                        :placeholder="$t('sdt')"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!frm.id"
                  md="6"
                >
                  <b-form-group
                    :label="$t('pass')"
                    label-for="password"
                  >
                    <validation-provider
                      v-if="!$route.params.id"
                      #default="{ errors }"
                      name="mật khẩu"
                      rules="min:6"
                      vid="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="frm.password"
                        name="password"
                        :state="errors.length > 0 ? false:null"
                        type="password"
                        :placeholder="$t('mk')"
                      />
                      <small class="text-danger">Mật khẩu mặc định là "crt@123"</small>
                    </validation-provider>
                    <validation-provider
                      v-if="$route.params.id"
                      #default="{ errors }"
                      name="mật khẩu"
                      rules="min:6"
                      vid="password"
                    >
                      <b-form-input
                        v-model="frm.password"
                        name="password"
                        :state="errors.length > 0 ? false:null"
                        type="password"
                        :placeholder="$t('mk')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!--                    <span class="text-danger">{{ $t('Default pass') }}</span>-->
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <!--          <b-button-->
          <!--            v-if="frm.id"-->
          <!--            variant="danger"-->
          <!--            style="float: right; margin: 20px 0; color: #fff"-->
          <!--            @click="resetPass"-->
          <!--          >-->
          <!--            {{ $t('reset_pk') }}-->
          <!--          </b-button>-->
        </b-card-code>
      </b-col>
      <b-col cols="4">
        <b-card-code
          class="height-full"
          :title="$t('active_course')"
        >
          <b-form class="form_custom">
            <b-row>
              <b-col md="12">
                <b-form-group
                  :label="$t('kh')"
                  label-for="course"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('kh')"
                    rules=""
                  >
                    <app-collapse class="custom_box_input">
                      <app-collapse-item :title="$t('choose_kh')">
                        <b-form-input
                          v-model="filter.courseName"
                          type="text"
                          placeholder="Tìm khóa học"
                        />
                        <b-form-checkbox
                          v-for="course in courses"
                          :key="course.id"
                          v-model="frm.courseIds"
                          :value="course.id"
                          class="custom-control-danger"
                        >
                          <b-card-text style="margin-left: 30px">
                            {{ course.name }}
                          </b-card-text>
                        </b-form-checkbox>
                        <span
                          v-if="courses.length === 0"
                          class="text-danger"
                        >
                          {{ $t('no_course') }}
                        </span>
                      </app-collapse-item>
                    </app-collapse>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--              <b-col md="12">-->
              <!--                <b-form-group-->
              <!--                  label="Combo khóa học"-->
              <!--                  label-for="combo"-->
              <!--                >-->
              <!--                  <validation-provider-->
              <!--                    #default="{ errors }"-->
              <!--                    name="combo khóa học"-->
              <!--                    rules=""-->
              <!--                  >-->
              <!--                    <app-collapse class="custom_box_input">-->
              <!--                      <app-collapse-item title="Chọn combo khóa học">-->
              <!--                        <b-form-input-->
              <!--                          v-model="filter.comboName"-->
              <!--                          type="text"-->
              <!--                          placeholder="Tìm combo khóa học"-->
              <!--                          @change="loadComboItems"-->
              <!--                        />-->
              <!--                        <b-form-checkbox-->
              <!--                          v-for="combo in combos"-->
              <!--                          :key="combo.id"-->
              <!--                          v-model="frm.comboIds"-->
              <!--                          :value="combo.id"-->
              <!--                          class="custom-control-danger"-->
              <!--                        >-->
              <!--                          {{ combo.name }}-->
              <!--                        </b-form-checkbox>-->
              <!--                        <span-->
              <!--                          v-if="combos.length === 0"-->
              <!--                          class="text-danger"-->
              <!--                        >-->
              <!--                          Không tìm thấy combo khóa học-->
              <!--                        </span>-->
              <!--                      </app-collapse-item>-->
              <!--                    </app-collapse>-->
              <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                  </validation-provider>-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col md="12">
                <label>{{ $t('duration') }} <span class="text-danger">(*)</span></label>
                <validation-provider
                  :name="$t('duration')"
                >
                  <b-form-input
                    id="duration"
                    v-model="frm.duration"
                    type="number"
                    :state="errorMsg.duration ? false:null"
                    :placeholder="$t('choose_month')"
                  />
                  <small
                    v-if="errorMsg.duration"
                    class="text-danger"
                  >{{ errorMsg.duration[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </b-card-code>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button
          variant="danger"
          style="float: right; margin: 20px 0; color: #fff"
          class="btn-sm"
          @click="handleSubmit"
        >
          {{ $t('save_info') }}
        </b-button>
      </b-col>
      <b-col cols="12">
        <b-card-code title="">
          <table
            class="table b-table"
            style="margin-top: 10px"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                >
                  {{ $t('course_actived') }}
                </th>
                <th
                  scope="col"
                  width="200"
                  class="text-center"
                >
                  {{ $t('date_expire') }}
                </th>
                <th
                  scope="col"
                  width="130"
                  class="text-center"
                >
                  {{ $t('duration') }}
                </th>
                <th
                  scope="col"
                  width="15"
                />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="course in registeredCourses"
                :key="course.id"
              >
                <td><strong>{{ course.course_name }}</strong>
                  <b-badge
                    v-if="course.status === 0"
                    variant="light-danger ml-1"
                  >
                    Hết hạn
                  </b-badge>
                </td>
                <td class="text-center">
                  {{ convertDate(course.expires_at) }}
                </td>
                <td
                  v-if="!isChangeDuration || idChangeDuration !== course.id"
                  class="text-center"
                >{{ course.duration ? course.duration + ' tháng' : '' }} </td>
                <td
                  v-if="isChangeDuration && idChangeDuration === course.id"
                  class="text-center"
                >
                  <b-form-input
                    v-model="duration"
                    type="number"
                    placeholder="Số tháng"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-code>
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormCheckbox, BOverlay, BCardText, BBadge, BFormSelect,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import config from '@/config'

export default {
  components: {
    BRow,
    vSelect,
    BCol,
    BBadge,
    BCardCode,
    BFormSelect,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BButton,
    ValidationObserver,
    BForm,
    BFormCheckbox,
    BOverlay,
    AppCollapse,
    BCardText,
    AppCollapseItem,
  },
  data() {
    return {
      isLoading: false,
      sourceOption: config.source,
      frm: {
        id: '',
        name: '',
        email: '',
        phoneNumber: '',
        password: '',
        courseIds: [],
        duration: '',
        tag: [],
        source: '',
      },
      courses: [],
      tagOptions: [],
      combos: [],
      registeredCourses: [],
      isChangeDuration: false,
      idChangeDuration: '',
      duration: null,
      errorMsg: '',
      required,
      filter: {
        courseName: '',
        comboName: '',
      },
    }
  },
  watch: {
    'filter.courseName': function () {
      this.loadCourseItems()
    },
  },

  created() {
    this.loadCourseItems()
    this.frm.id = this.$route.params.id
    if (this.frm.id) this.loadInfo()
  },
  methods: {
    loadCourseItems() {
      const params = {
        perPage: 1000,
        page: 1,
        status: 1,
        key: this.filter.courseName,
      }
      this.$http.get('/courses', {
        params,
      })
        .then(res => {
          this.courses = res.data.data
        }).catch()
        .finally(() => {
        })
    },
    loadInfo() {
      this.$http.get(`/accounts/${this.frm.id}/edit`)
        .then(res => {
          const tags = []
          this.frm.id = res.data.id
          this.frm.name = res.data.name
          this.frm.email = res.data.email
          this.frm.phoneNumber = res.data.phone
          this.registeredCourses = res.data.user_courses
          this.frm.source = res.data.source
          if (res.data.tags) {
            res.data.tags.map(v => {
              tags.push(v.id)
            })
            this.frm.tag = tags
          }
        }).catch()
        .finally(() => {
        })
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          const formData = new FormData()
          formData.append('name', this.frm.name)
          formData.append('email', this.frm.email)
          formData.append('phone_number', this.frm.phoneNumber)
          const tags = []
          const newTags = []
          if (this.frm.tag.length) {
            this.frm.tag.map(v => {
              v === parseInt(v) ? tags.push(v) : newTags.push({ name: v })
            })
            formData.append('tags', JSON.stringify(tags))
            formData.append('newTags', JSON.stringify(newTags))
          }
          if (this.frm.password) {
            formData.append('password', this.frm.password)
          }
          formData.append('status', 1)
          if (this.frm.password) formData.append('password', this.frm.password)

          if (this.frm.source) formData.append('source', this.frm.source)

          if (this.frm.courseIds.length) {
            formData.append('course_ids', JSON.stringify(this.frm.courseIds))
          }
          if (this.frm.duration) {
            formData.append('duration', this.frm.duration)
          }
          if (this.frm.id) formData.append('_method', 'PUT')
          const url = this.frm.id ? `/accounts/${this.frm.id}` : '/accounts'
          this.$http({
            method: 'post',
            url,
            data: formData,
          })

          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.frm.courseIds = []
              this.frm.duration = ''
              this.loadInfo()
              this.errorMsg = ''
              this.$bvToast.toast(this.$t('message.update_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
              if (error.response.status === 422) {
                this.errorMsg = error.response.data.errors
              } else {
                this.errorMsg = [error.response.data.error]
              }
              this.$refs.simpleRules.setErrors(this.errorMsg)
              this.$bvToast.toast(this.$t('message.update_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              if (this.frm.id) {
                this.loadInfo()
              }

              this.isLoading = false
            })
        }
      })
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
    // loginToAccount() {
    //   this.$http.get(`/login-to-account/${this.frm.id}`)
    //     .then(res => {
    //       if (res.data.access_token) {
    //         console.log(process.env.VUE_APP_ELEARNING_URL)
    //         window.open(`${process.env.VUE_APP_ELEARNING_URL}?access_token=${res.data.access_token}`, '_blank')
    //       }
    //     }).catch()
    //     .finally(() => {
    //     })
    // },
  },
}
</script>
